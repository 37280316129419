/* eslint-disable jsx-a11y/anchor-is-valid */
import { trackStoreLocatorSearchEvent } from '@ee-monorepo/shared/utilities/functions';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { Location, LocationType } from '@ee-monorepo/shared/utilities/types';
import {
  isLocationAllowedByUser,
  selectHasResults,
} from '@ee-monorepo/store-locator/shared/data-access';
import RoomIcon from '@mui/icons-material/Room';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { ListItem, Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import s from './merchant-location-input.module.scss';
import { useMerchantSearchInput } from './useMerchantLocationInput';

import { Button } from '@ee-monorepo/shared/ui/button';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { colors } from '@snap/colors';
import { GeoLocatorButton } from './geo-locator-button';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
export interface MerchantLocationInputProps {
  variant?: 'normal' | 'invert' | 'map' | 'storelocatornewui';
  isCollapsable?: boolean;
  onSelected?: (item: Location) => void;
  buttonProps?: ButtonProps;
  initialLocation?: Location;
  onBeforeSelect?: () => void;
}

export interface ButtonProps {
  showButton: boolean;
  buttonText: string;
}

export function MerchantLocationInput(props: MerchantLocationInputProps) {
  const { variant, buttonProps, onSelected, initialLocation, onBeforeSelect } =
    props;
  const t = useTranslations();
  const inputRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const {
    suggestions,
    suggestionsLoading,
    setSelected,
    setInputValue,
    inputValue,
  } = useMerchantSearchInput();
  const isMobile = useHandHeld();
  const isLocationAllowed = useSelector(isLocationAllowedByUser);
  const hasResults = useSelector(selectHasResults);
  const [key, setKey] = useState(1);
  const { tracking } = useTrackingContext();

  useEffect(() => {
    if (isMobile && !isLocationAllowed) {
      inputRef.current.focus();
      setCollapsed(false);
    }
  }, [isMobile, isLocationAllowed]);

  useEffect(() => {
    if (isMobile && hasResults) {
      inputRef.current.blur();
      setCollapsed(true);
    }
  }, [isMobile, hasResults]);

  const [enteredInput, setEnteredInput] = useState('');

  const formatSelection = useCallback((location: Location) => {
    if (location.city && location.state) {
      return `${location.city}, ${location.state}`;
    } else {
      return ``;
    }
  }, []);

  // set initial input value
  useEffect(() => {
    if (initialLocation) {
      setInputValue(formatSelection(initialLocation));
    }
  }, [initialLocation, setInputValue, formatSelection]);

  const onKeyPressed = (e) => {
    if (e.key === 'Enter') {
      onBeforeSelect?.();
      const isZipCode = /^\d+$/.test(e.target.value);
      if (isZipCode) {
        setSelected(e.target.value);
      } else {
        const userInput = getLocationFromSuggestions();
        setSelectedHandler(userInput ? userInput : e.target.value);
      }
    }
  };

  const setSelectedHandler = useCallback(
    (option: LocationType) => {
      if (onSelected) {
        onSelected(option);
      } else if (option.zipCode) {
        onBeforeSelect?.();
        setSelected(option.zipCode);
      }
      inputRef.current.blur();
      trackStoreLocatorSearchEvent(formatSelection(option));
      tracking.logSearchEvent({
        search_term: formatSelection(option),
        search_type: 'location',
      });
    },
    [formatSelection, onSelected, setSelected, onBeforeSelect, tracking]
  );

  const getLocationFromSuggestions = useCallback(() => {
    if (!enteredInput.includes(',')) {
      return null;
    }
    const city = enteredInput?.split(',')[0].trim();
    const state = enteredInput?.split(',')[1].trim();
    const location = suggestions?.find(
      (obj) =>
        obj.city.toLowerCase() === city.toLowerCase() &&
        obj.state.toLowerCase() === state.toLowerCase()
    );
    return location;
  }, [enteredInput, suggestions]);

  return (
    <div
      data-testid="merchant-location-input"
      className={cn(s.autoCompleteContainer, {
        [s.autoCompleteContainerIsCollapsable]: props.isCollapsable,
        [s.autoCompleteContainerCollapsed]: props.isCollapsable && collapsed,
      })}
    >
      {variant === 'map' && (
        <a
          data-testid="toggle"
          className={cn('flex', 'items-center', s.searchIcon)}
          onClick={() => {
            if (collapsed) {
              inputRef.current.focus();
            }
            setCollapsed(!collapsed);
          }}
        >
          <RoomIcon className="text-SnapGrey400" />
        </a>
      )}
      {variant === 'storelocatornewui' && (
        <a
          data-testid="toggle"
          className={cn('flex', 'items-center', s.searchIcon)}
        >
          <RoomOutlinedIcon className="text-SnapBlue" />
        </a>
      )}
      <form
        className={cn('flex', 'w-full', s.form)}
        onSubmit={(e) => e.preventDefault()}
      >
        <Autocomplete
          key={key}
          popupIcon={null}
          clearIcon={null}
          inputValue={inputValue}
          value={initialLocation}
          getOptionLabel={(option: Location) =>
            typeof option === 'string' ? option : formatSelection(option)
          }
          isOptionEqualToValue={(option: Location, value: Location) => {
            return option.zipCode === value.zipCode;
          }}
          filterOptions={(x) => x}
          options={suggestions}
          autoHighlight
          autoComplete
          includeInputInList
          onChange={(_, newValue: Location) => {
            if (!newValue) {
              return;
            }
            const userInput = getLocationFromSuggestions();
            setSelectedHandler(userInput ? userInput : newValue);
          }}
          onInputChange={(_, newInputValue) => {
            const isZipCode = /^\d+$/.test(newInputValue);
            const userInput = getLocationFromSuggestions();
            if (!isZipCode && newInputValue !== '') {
              setInputValue(userInput ? enteredInput : newInputValue);
            }
          }}
          PopperComponent={inputValue ? Popper : () => null}
          className={cn(s.searchInput)}
          classes={{
            inputRoot: s.inputRoot,
          }}
          freeSolo={suggestionsLoading}
          noOptionsText={t('shared.location_input_no_options') as string}
          renderOption={(props, option: LocationType) => (
            <ListItem
              {...props}
              onClick={() => {
                setSelectedHandler(option);
                setInputValue(formatSelection(option));
              }}
            >
              {formatSelection(option)}
            </ListItem>
          )}
          renderInput={(params) => (
            <SearchInput
              {...params}
              sx={{
                '&.expandedVariant .MuiFormLabel-root, &.storelocatornewuiVariant .MuiFormLabel-root':
                  {
                    left: 32,
                    top: -2,
                  },
                '&.invertVariant .MuiInputBase-root': {
                  backgroundColor: colors.SnapGrey100,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.SnapGrey400,
                },
                '&.storelocatornewuiVariant .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.SnapBlue,
                },
                '&.mapVariant .MuiInputBase-root,&.storelocatornewuiVariant .MuiInputBase-root':
                  {
                    backgroundColor: colors.SnapWhite,
                  },
                '&.collapsedVariant .MuiFormLabel-root, &.collapsedVariant .MuiInputBase-input':
                  {
                    opacity: 0,
                  },
                '&.collapsedVariant .MuiInputBase-input': {
                  paddingRight: '0 !important',
                },
                '&.expandedVariant .MuiInputBase-root,&.storelocatornewuiVariant .MuiInputBase-root':
                  {
                    paddingLeft: '32px !important',
                  },
              }}
              label={t('shared.location_input_placeholder') as string}
              variant={
                variant !== 'map' && variant !== 'storelocatornewui'
                  ? 'filled'
                  : 'outlined'
              }
              fullWidth
              className={cn({
                invertVariant: variant === 'invert',
                collapsedVariant: variant === 'map' && collapsed,
                expandedVariant: variant === 'map' && !collapsed,
                mapVariant: variant === 'map',
                storelocatornewuiVariant: variant === 'storelocatornewui',
              })}
              inputRef={inputRef}
              InputProps={{
                ...params?.InputProps,
              }}
              onChange={(e) => {
                if (e.target.value.length === 0 && onSelected) {
                  onSelected(null);
                }
                setEnteredInput(e.target.value);
                setInputValue(e.target.value);
              }}
              onKeyDown={(e) => {
                //onKeyPress is depreciated
                onKeyPressed(e);
              }}
            />
          )}
        />
        {buttonProps?.showButton && (
          <Button
            className={cn(s.submitButton)}
            onClick={() => {
              if (enteredInput.length > 0) {
                setSelected(enteredInput);
              }
            }}
            data-testid="location-submit-button"
          >
            {buttonProps.buttonText}
          </Button>
        )}
      </form>
      {(props.isCollapsable || variant === 'storelocatornewui') && (
        <a
          data-testid="expanded-icon"
          className={cn(s.expandedIcon, {
            hidden: collapsed && variant !== 'storelocatornewui',
          })}
        >
          <GeoLocatorButton
            onButtonClick={() => {
              setKey(Date.now());
              setInputValue('');
              onBeforeSelect?.();
            }}
          />
        </a>
      )}
    </div>
  );
}

const SearchInput = styled(TextField)(({ theme }) => ({
  root: {
    '& .MuiInputLabel-outlined': {
      transition: theme.transitions.create(
        ['color', 'transform', 'opacity', 'left'],
        {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }
      ),
    },
  },
}));
