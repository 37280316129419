import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '@ee-monorepo/shared/utilities/hooks';
import {
  selectLocationSuggestions,
  selectLocationSuggestionsLoading,
} from '@ee-monorepo/store-locator/shared/data-access';
import {
  useStoreLocatorActions,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';

export function useMerchantSearchInput() {
  const { suggestMerchantsLocations } = useStoreLocatorActions();
  const { pushLocation } = useStoreLocatorRouterActions();
  const locationSuggestions = useSelector(selectLocationSuggestions);
  const locationSuggestionsLoading = useSelector(
    selectLocationSuggestionsLoading
  );
  const [inputValue, setInputValue] = useState('');
  const inputValueDebounced = useDebounce(inputValue, 800);

  // on input change trigger API for suggestions
  useEffect(() => {
    const suggest = async (hint: string) => {
      if (hint.includes(',')) {
        return;
      }
      suggestMerchantsLocations(hint);
    };
    if (inputValueDebounced) {
      suggest(inputValueDebounced);
    }
  }, [inputValueDebounced, suggestMerchantsLocations]);

  return {
    suggestions: locationSuggestions ?? [],
    suggestionsLoading: locationSuggestionsLoading,
    setInputValue,
    setSelected: (zipCode) => pushLocation(zipCode),
    inputValue,
  };
}
