import {
  isLocationAllowedByUser,
  useCurrentGeolocation,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import LocationSearchingIcon from '@mui/icons-material/MyLocation';
import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface GeoLocatorButtonProps {
  onButtonClick?: () => void;
  variant?: 'default' | 'outline';
}

export const GeoLocatorButton = ({
  onButtonClick,
  variant,
}: GeoLocatorButtonProps) => {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const router = useRouter();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const isLocationAllowed = useSelector(isLocationAllowedByUser);
  const [locationAccess, setLocationAccess] = useState(isLocationAllowed);
  const { pushGeoCoordinates } = useStoreLocatorRouterActions();
  const geolocationContext = useCurrentGeolocation();

  // Get the latitude and longitude from the url once the route is changed
  useEffect(() => {
    const handleGeoLocationChange = () => {
      const location = new URLSearchParams(window.location.search);
      setLatitude(location.get('latitude'));
      setLongitude(location.get('longitude'));
    };
    router.events?.on('routeChangeStart', handleGeoLocationChange);
    router.events?.on('routeChangeComplete', handleGeoLocationChange);
    return () => {
      router.events?.off('routeChangeStart', () => {});
      router.events?.off('routeChangeComplete', () => {});
    };
  }, [router.events]);

  // Update location access state when user changes location access
  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((status) => {
      status.onchange = () => {
        setLocationAccess(status.state === 'granted');
      };
    });
  }, []);

  // Reset flag for geolocator icon when user changes location
  useEffect(() => {
    if (
      geolocationContext?.latitude?.toString() !== latitude ||
      geolocationContext?.longitude?.toString() !== longitude
    ) {
      setIsCurrentLocation(false);
    }
  }, [
    geolocationContext?.latitude,
    geolocationContext?.longitude,
    latitude,
    longitude,
  ]);

  // Get user's current location and push coordinates to store locator
  const getUserCurrentLocation = () => {
    if (locationAccess) {
      if (geolocationContext) {
        setIsCurrentLocation(true);
        pushGeoCoordinates({
          latitude: geolocationContext.latitude,
          longitude: geolocationContext.longitude,
        });
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        title={!locationAccess ? t('shared.enable_location') : ''}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            onButtonClick && onButtonClick();
            setOpen(true);
            getUserCurrentLocation();
          }}
          data-testid="geo-locator-button"
        >
          <LocationSearchingIcon
            className={cn({
              [cn('text-SnapGrey500')]:
                !isCurrentLocation && variant !== 'outline',
              [cn('text-SnapMediumBlue')]: isCurrentLocation,
              [cn('text-SnapBlue')]: variant === 'outline',
            })}
          />
          {variant === 'outline' && (
            <span className="p-1 bodyHeavyFont text-SnapBlue hover:text-SnapMediumBlue">
              {t('shared.use_my_location')}
            </span>
          )}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};
